header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ========== CTA =======*/

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*========= HEADER SOCIALS ===== */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 4rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*========= ME ===== */

.me {
  width: 20rem;
  height: 20rem;
  position: inherit;
  left: calc(50% - 10rem);
  margin-top: 3rem;
  border-radius: 50rem 50rem 50rem 50rem;
  overflow: hidden;
  padding: 0rem;
}

/*========= SCROLL DOWN ===== */

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 10rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/*========= MEDIA QUERIES (MEDIUM) =======*/

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/*========= MEDIA QUERIES (Small) =======*/

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll_down {
    display: none;
  }
}
