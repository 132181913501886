.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: var(--color-bg-hover-variant);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 0.2rem 0 0.2rem;
}
.portfolio__item h4 {
  margin: 0.2rem 0 1rem;
  font-weight: lighter;
}
.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/*====== MEDIA QUERIES (MEDIUM DEVICES) ==== */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr;

    gap: 1.2rem;
  }
}

/*====== MEDIA QUERIES (SMALL DEVICES) ==== */

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr;

    gap: 1rem;
  }
}
